// React
import React from "react";

// Material
import { Box, Container, Typography } from "@mui/material";

// Assets
import computer from "../Assets/computer.jpg";

function About() {
  return (
    <Container
      sx={{
        width: "500px",
        height: "50vh",
        maxWidth: "80vw",
        textAlign: "left",
        overflowY: "auto",
      }}
    >
      <Typography variant="subtitle2" component="h3">
        <b>About Me</b>
      </Typography>
      <br />
      <Typography variant="caption" component="p">
        I'm into science, fitness, and nature.
        <br />
        <br />
        Originally from the Pacific Northwest, I graduated from the University
        of Washington in 2017 with a Bachelor's degree in Applied Physics. I
        first learned to code as an undergraduate researcher, programming
        microcontrollers on sounding rockets. I was interested in plotting
        flight trajectories, and in learning about sensors like accelerometers
        and gyroscopes, my first language was C.
        <br />
        <br />
        When I graduated, I packed everything I owned into a 2004 Honda Civic and drove across the United States to Austin, TX. I didn't have a plan, but I came to find out that's why a lot of people end up in Austin. I spent two years turning wrenches on the outskirts of town, learning how semiconductors are made.
        <br />
        <br />
        In 2019, I saved up $10,000 to put myself through a full-stack immersion software development bootcamp, and landed my first developer job. This commenced a years long journey of studying to become a better engineer. 
        <br />
        <br />
        I was later hired at the Idaho National Laboratory, where I am now.
      </Typography>
      <br />
      <Container sx={{ textAlign: "center", padding: 0 }}>
        <Box
          component="img"
          sx={{
            height: "20%",
            width: "100%",
          }}
          src={computer}
        />
      </Container>
    </Container>
  );
}

export default About;
